export function initShopLinks() {
  document.addEventListener("afterBlitzInject", () => {
    const accountLink = document.getElementById("js-account-link");
    const cartLink = document.getElementById("js-cart-link");

    if (accountLink && cartLink) {
      const isAccountActive =
        window.location.pathname.includes("/shop/customer");
      const isLoginActive = window.location.pathname.includes(
        "/shop/customer/login"
      );
      const isCartActive = window.location.pathname.includes("/shop/cart");

      if (isAccountActive || isLoginActive) {
        accountLink.classList.add("is-active");
      }

      if (isCartActive) {
        cartLink.classList.add("is-active");
      }
    }
  });
}
