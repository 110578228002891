import opentype from "opentype.js";

export async function generateIconsCharset() {
  const charsetContainer = document.getElementById("charset-icons");
  if (charsetContainer) {
    const cssFontFamily = charsetContainer.dataset.fontFamily;
    const woffUrl = charsetContainer.dataset.woffUrl;
    const font = await opentype.load(woffUrl);
    appendCharset(font, charsetContainer, cssFontFamily);
  }
}

function appendCharset(font, charsetContainer, cssFontFamily) {
  const glyphs = font.glyphs.glyphs;
  const spaceGlyphName = "space";
  let htmlString = "";
  for (const idx in glyphs) {
    if (glyphs[idx].name !== spaceGlyphName && glyphs[idx].unicode) {
      htmlString += glyphTemplate(glyphs[idx], cssFontFamily);
    }
  }
  charsetContainer.insertAdjacentHTML("beforeend", htmlString);
}

function glyphTemplate(glyph, cssFontFamily) {
  return `<div class="text-center inline-block mr-0.25 mb-0.5 md:mr-0.5 md:mb-1 xl:mr-1">
            <div class="flex justify-center items-center overflow-hidden leading-none
            text-i24 w-3 h-2 lg:text-i32 lg:w-5 lg:h-3.5 first-letter:2xl:text-i44 2xl:w-6 2xl:h-4" style="font-family: ${cssFontFamily}">
              &#${glyph.unicode};
            </div>
            <div class="mt-0.25 whitespace-nowrap text-ellipsis overflow-hidden opacity-30 text-xs leading-none lg:mt-0 lg:text-md">
              ${glyph.name}
            </div>
          </div>`;
}
