import { isDarkModeActive } from "./darkmode";

export function setLanguageSupportMapImage() {
  const langSupportImg = document.querySelector("#js-lang-support-img");
  if (langSupportImg) {
    langSupportImg.src = isDarkModeActive()
      ? langSupportImg.dataset.srcDark
      : langSupportImg.dataset.src;
  }
}
