import { initDarkModeToggle } from "./darkmode";
import Headroom from "headroom.js";

export function initMenu() {
  const header = document.querySelector("#js-header");
  const shade = document.querySelector("#js-menu-shade");
  const sandwich = document.querySelector("#js-menu-sandwich");
  const menuOpenClass = "menu-open";

  shade.addEventListener("click", (e) => {
    header.classList.remove(menuOpenClass);
  });

  sandwich.addEventListener("click", (e) => {
    header.classList.toggle(menuOpenClass);
  });

  initDarkModeToggle();

  const headroom = new Headroom(header, {
    offset: {
      up: 0,
      down: 100,
    },
    tolerance: 10,
  });
  headroom.init();
}
