import { trackEvent } from "./tracking-events";

export function initDarkModeToggle() {
  document
    .querySelector("#js-toggle-dark-mode")
    .addEventListener("click", () => {
      if ("theme" in localStorage) {
        localStorage.theme = localStorage.theme == "light" ? "dark" : "light";
      } else {
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          localStorage.theme = "light";
        } else {
          localStorage.theme = "dark";
        }
      }
      trackEvent("DARK_MODE_TOGGLE");
      location.reload();
    });
}

export function isDarkModeActive() {
  return document.querySelector("html").classList.contains("dark");
}
