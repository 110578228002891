import { createApp } from "petite-vue";

function initTypeTester(tester) {
  const textNode = tester.querySelector(".js-tester-text");
  const initialFontSize = window
    .getComputedStyle(textNode)
    .getPropertyValue("font-size")
    .replace("px", "");
  const initialFontFamily = window
    .getComputedStyle(textNode)
    .getPropertyValue("font-family");
  const initialTextAlign = window
    .getComputedStyle(textNode)
    .getPropertyValue("text-align");
  const initialLineHeight =
    Math.round(
      (window
        .getComputedStyle(textNode)
        .getPropertyValue("line-height")
        .replace("px", "") /
        initialFontSize) *
        10
    ) / 10;
  const initialTextTransform = window
    .getComputedStyle(textNode)
    .getPropertyValue("text-transform");

  createApp({
    $delimiters: ["${", "}"],
    controls: false,
    fontSize: initialFontSize,
    fontFamily: initialFontFamily,
    textAlign: initialTextAlign,
    letterSpacing: 0,
    lineHeight: initialLineHeight,
    textTransform: initialTextTransform,
    otControls: false,
    otArray: [],
    get ffs() {
      return this.otArray.map((s) => '"' + s + '"').join(", ");
    },
    isOtActive(featureFlag) {
      return this.otArray.includes(featureFlag);
    },
    toggleOtFeature(featureFlag) {
      if (this.isOtActive(featureFlag)) {
        this.deactivateOtFeature(featureFlag);
      } else {
        this.activateOtFeature(featureFlag);
      }
    },
    activateOtFeature(featureFlag) {
      this.otArray.push(featureFlag);
    },
    deactivateOtFeature(featureFlag) {
      const idx = this.otArray.indexOf(featureFlag);
      this.otArray.splice(idx, 1);
    },
    construct() {
      this.controls = true;
    },
  }).mount("#" + tester.id);
}

export function initTypeTesters() {
  const testers = document.querySelectorAll(".js-tester");
  for (let i = 0; i < testers.length; i++) {
    initTypeTester(testers[i]);
  }
}
