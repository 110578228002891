import { createApp } from "petite-vue";

export function initLicenseeAddress() {
  const licenseeAddressFields = document.querySelector("#js-licensee-address");
  if (licenseeAddressFields) {
    createApp({
      $delimiters: ["${", "}"],
      licenseeSameAsBilling: licenseeAddressFields.querySelector(
        "#licenseeSameAsBilling"
      )?.checked,
      licenceHolder:
        licenseeAddressFields.querySelector("#licenceHolder")?.value,
      clearLicenceHolder() {
        if (this.licenseeSameAsBilling) {
          this.licenceHolder = "";
        }
      },
    }).mount(licenseeAddressFields);
  }
}
