import { createApp, reactive } from "petite-vue";
import opentype from "opentype.js";

async function initVariableTypeTester(tester) {
  const textNode = tester.querySelector(".js-tester-text");
  const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
  const autoPlay = mediaQuery && !mediaQuery.matches;

  const animationStore = reactive({
    animationPlaying: false,
    animationSpeed: 1,
    lastStepChangeTime: 0,
    autoPlayActive: autoPlay,
    axes: {},
    get fvs() {
      return Object.keys(this.axes)
        .map((key) => `"${key}" ${this.axes[key].value}`)
        .join(", ");
    },
    setRandomFvsValues() {
      for (const axis in this.axes) {
        this.axes[axis].value = Math.floor(
          Math.random() * (this.axes[axis].max - this.axes[axis].min) +
            this.axes[axis].min
        );
      }
    },
    cycleAnimationSpeed() {
      this.animationSpeed++;
      if (this.animationSpeed > 3) {
        this.animationSpeed = 1;
      }
    },
    startAnimation() {
      this.autoPlayActive = true;
      this.animationPlaying = true;
      this.lastStepChangeTime = 0;
      window.requestAnimationFrame(this.animationStep);
    },
    stopAnimation() {
      this.autoPlayActive = false;
      this.pauseAutoplay();
    },
    pauseAutoplay() {
      this.animationPlaying = false;
      this.lastStepChangeTime = 0;
    },
    resumeAutoplay() {
      if (this.autoPlayActive) {
        this.startAnimation();
      }
    },
    animationStep(currentTime) {
      if (currentTime - animationStore.lastStepChangeTime > 30) {
        // guarantee one frame max every 30ms
        animationStore.lastStepChangeTime = currentTime;
        for (const axisKey in animationStore.axes) {
          const axis = animationStore.axes[axisKey];
          const step =
            ((axis.max - axis.min) / 100) * animationStore.animationSpeed;
          if (axis.countingUp) {
            axis.value = axis.value + step;
          } else {
            axis.value = axis.value - step;
          }
          if (axis.value >= axis.max) {
            axis.countingUp = false;
          }
          if (axis.value <= axis.min) {
            axis.countingUp = true;
          }
        }
      }
      if (animationStore.animationPlaying) {
        window.requestAnimationFrame(animationStore.animationStep);
      }
    },
  });

  createApp({
    $delimiters: ["${", "}"],
    animationStore,
    variableLabel: textNode.dataset.variableLabel,
    varFontData: JSON.parse(textNode.dataset.varFontData),
    controls: false,
    otControls: false,
    otArray: [],
    get ffs() {
      return this.otArray.map((s) => '"' + s + '"').join(", ");
    },
    isOtActive(featureFlag) {
      return this.otArray.includes(featureFlag);
    },
    toggleOtFeature(featureFlag) {
      if (this.isOtActive(featureFlag)) {
        this.deactivateOtFeature(featureFlag);
      } else {
        this.activateOtFeature(featureFlag);
      }
    },
    activateOtFeature(featureFlag) {
      this.otArray.push(featureFlag);
    },
    deactivateOtFeature(featureFlag) {
      const idx = this.otArray.indexOf(featureFlag);
      this.otArray.splice(idx, 1);
    },
    async loadFontDataIntoStore() {
      const fontUrl = this.varFontData[this.variableLabel].assetUrl;
      const font = await opentype.load(fontUrl);
      const axesFontData = font.tables.fvar.axes;
      for (const i in axesFontData) {
        const axisData = {
          label: axesFontData[i].name.en,
          value: axesFontData[i].defaultValue,
          min: axesFontData[i].minValue,
          max: axesFontData[i].maxValue,
          countingUp: true,
        };
        animationStore.axes[axesFontData[i].tag] = axisData;
      }
    },
    async construct() {
      this.loadFontDataIntoStore();
      this.controls = true;
    },
  }).mount("#" + tester.id);

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        animationStore.resumeAutoplay();
      } else {
        animationStore.pauseAutoplay();
      }
    });
  });

  observer.observe(textNode);
}

export function initVariableTypeTesters() {
  const testers = document.querySelectorAll(".js-tester-variable");
  testers.forEach((tester) => initVariableTypeTester(tester));
}
