import { initMenu } from "./modules/menu";
import { generateCharset } from "./modules/charset-generator";
import { generateIconsCharset } from "./modules/icons-charset-generator";
import { setLanguageSupportMapImage } from "./modules/language-support-map";
import { initTypeTesters } from "./modules/type-tester";
import { initVariableTypeTesters } from "./modules/type-tester-variable";
import { initTryPage } from "./modules/try-page";
import { initBuyPage } from "./modules/buy-page";
import { initAddressFields } from "./modules/address-form";
import { initOrderSummary } from "./modules/order-summary";
import { initExpandFromHash } from "./modules/details";
import { initGallery } from "./modules/gallery";
import { initFlashMessages } from "./modules/flash-message";
import { initLicenseeAddress } from "./modules/licensee-address";
import { initFontsFilter } from "./modules/fonts-filter";
import { initShopLinks } from "./modules/shop-links";
import { initStickyBuyButton } from "./modules/sticky-buy-button";
import { initTrackingEvents } from "./modules/tracking-events";

initMenu();
generateCharset();
generateIconsCharset();
setLanguageSupportMapImage();
initTypeTesters();
initVariableTypeTesters();
initTryPage();
initBuyPage();
initAddressFields();
initOrderSummary();
initExpandFromHash();
initGallery();
initFlashMessages();
initLicenseeAddress();
initFontsFilter();
initShopLinks();
initStickyBuyButton();
initTrackingEvents();
