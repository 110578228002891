export function initExpandFromHash() {
  expandFromHash(window.location.hash);
  window.onhashchange = () => expandFromHash(window.location.hash);
}

function expandFromHash(hash) {
  if (!hash) {
    return;
  }

  document.querySelectorAll("details").forEach((detail) => {
    const id = detail.getAttribute("id");
    if (id && `#${id}` === hash) {
      detail.setAttribute("open", true);
    }
  });
}
