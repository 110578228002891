import { createApp, reactive, nextTick } from "petite-vue";
import { trackEvent } from "./tracking-events";

const filterStore = reactive({
  activeFilters: [],
  remainingPossibleFilters: [],
  activate(tag) {
    if (this.activeFilters.indexOf(tag) === -1) {
      this.activeFilters.push(tag);
    }
  },
  deactivate(tag) {
    this.activeFilters.splice(this.activeFilters.indexOf(tag), 1);
  },
  isActive(tag) {
    return this.activeFilters.includes(tag);
  },
  toggle(tag) {
    if (this.isActive(tag)) {
      this.deactivate(tag);
    } else {
      this.activate(tag);
    }
    this.collectRemainingFilters();
    this.updateUrl();
  },
  async collectRemainingFilters() {
    await nextTick();
    const remainingPossibleFilters = [];
    document.querySelectorAll(".js-fonts-teaser").forEach((teaser) => {
      // we’ll get some duplicates here, but it’s not worth stripping them out imo
      remainingPossibleFilters.push(...JSON.parse(teaser.dataset.tags));
    });
    this.remainingPossibleFilters = remainingPossibleFilters;
  },
  updateUrl() {
    let url = new URL(window.location.href);
    if (this.activeFilters.length > 0) {
      url.searchParams.set("filters", this.activeFilters.join(","));
    } else {
      url.searchParams.delete("filters");
    }
    window.history.replaceState({}, "", url);
  },
});

function initFilterControls(filter) {
  createApp({
    $delimiters: ["${", "}"],
    filterStore,
    open: false,
    toggleFilter() {
      trackEvent("FONTS_FILTER_TOGGLE");
      this.open = !this.open;
    },
    parseInitialFiltersFromUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const filters = urlParams.get("filters")?.split(",");
      filters?.forEach((tag) => {
        this.filterStore.activate(tag);
      });
    },
    construct() {
      this.filterStore.tagsDict = JSON.parse(
        document.querySelector("#js-tags-dict").dataset.tagsDict
      );
      this.parseInitialFiltersFromUrl();
      this.filterStore.collectRemainingFilters();
    },
  }).mount(filter);
}

function initFontsTeaser(teaser) {
  createApp({
    $delimiters: ["${", "}"],
    filterStore,
    tags: JSON.parse(teaser.dataset.tags),
    get isVisible() {
      // all active filters need to be in the teaser’s tags
      return this.filterStore.activeFilters.reduce(
        (result, tag) => this.tags.includes(tag) && result,
        true
      );
    },
    construct() {},
  }).mount(teaser);
}

export function initFontsFilter() {
  const filter = document.querySelector("#js-fonts-index-filter");
  if (filter) {
    initFilterControls(filter);
  }
  const fontsTeasers = document.querySelectorAll(
    "#js-fonts-index-results .js-fonts-teaser"
  );
  fontsTeasers.forEach((teaser) => initFontsTeaser(teaser));
}
