let fathom;

const trackingEvents = {
  BUY_PAGE_ADD_TO_CART: "BUPVIYPV",
  BUY_PAGE_REMOVE_FROM_CART: "BRRDQ5VI",
  CART_CHANGE_LICENCE: "LDDGDBVY",
  CART_CLEAR: "HX4EJRJO",
  CART_QUOTE_DOWNLOAD: "XBIFATRA",
  CART_SHARE_CART: "GBCJT7LR",
  CHECKOUT_COMPLETE: "IC1PKQGS",
  CHECKOUT_START: "PIMULDMS",
  DARK_MODE_TOGGLE: "KM91OU9O",
  DOWNLOAD_DEMO: "DMB3PDG4",
  DOWNLOAD_TRY: "6S4KR5CI",
  FONTS_FILTER_TOGGLE: "RZWEOCNP",
  FONTS_PAGE_DOWNLOAD_SPECIMEN: "NFQPHG7B",
  FONTS_PAGE_OT_MODULE_ON_OFF: "AW8BKIX6",
  FONTS_PAGE_TOGGLE_SECTION_CHARSET: "SFMNLPSS",
  FONTS_PAGE_TOGGLE_SECTION_LANGUAGE: "JQKM9XDY",
  FONTS_PAGE_TOGGLE_SECTION_OT: "B2IFZRCR",
  MOBILE_HAMBURGER_TOGGLE: "6QEETAOM",
  NEWSLETTER_SUBSCRIBE: "V61PQUHX",
  ORDER_PAGE_DOWNLOAD_FONTS: "HLFHCG3P",
  ORDER_PAGE_DOWNLOAD_INVOICE: "GRJSVDIC",
  TESTER_SWITCH_ALIGNMENT: "WGSO6DPR",
  TESTER_SWITCH_CASE: "H6GVWOQP",
  TESTER_SWITCH_STYLE: "8SYU167T",
  TESTER_TOGGLE_OPENTYPE: "JD8FVYQJ",
  TESTER_VARIABLE_PLAY_PAUSE: "8T0IZ3RG",
  TESTER_VARIABLE_SPEED_CHANGE: "LJTHJLAW",
  USER_FORGOT_PASSWORD: "YSQ3HLPC",
};

export function initTrackingEvents() {
  window.addEventListener("load", () => {
    fathom = window.fathom;
    if (!fathom) return;

    document.querySelectorAll("[data-load-event-name]").forEach((element) => {
      trackEvent(element.dataset.loadEventName);
    });

    document.querySelectorAll("[data-click-event-name]").forEach((element) => {
      element.addEventListener("click", () => {
        trackEvent(element.dataset.clickEventName);
      });
    });

    document.querySelectorAll("[data-change-event-name]").forEach((element) => {
      element.addEventListener("change", () => {
        trackEvent(element.dataset.changeEventName);
      });
    });

    document.querySelectorAll("[data-toggle-event-name]").forEach((element) => {
      element.addEventListener("toggle", () => {
        trackEvent(element.dataset.toggleEventName);
      });
    });
  });
}

export function trackEvent(id) {
  if (!fathom || !trackingEvents[id]) return;
  fathom.trackGoal(trackingEvents[id], 0);
}
