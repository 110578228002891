import Flickity from "flickity";

export function initGallery() {
  document.querySelectorAll(".js-gallery").forEach((element) => {
    window.addEventListener("load", () => {
      const gallery = new Flickity(element, {
        prevNextButtons: false,
        cellAlign: "left",
        wrapAround: true,
      });

      gallery.on("staticClick", (_event, _pointer, cellElement) => {
        if (!cellElement || cellElement.classList.contains("is-selected")) {
          return;
        }
        gallery.next();
      });

      const videos = element.querySelectorAll("video");

      /*
       * On initial render, Flickity (2.3.0) does not await for <video> elements to be loaded,
       * therefore slide elements that have <video> elements are not correctly placed.
       * The below code awaits all loaded videos and triggers a Flickity .resize():
       * https://flickity.metafizzy.co/api.html#resize
       */
      if (videos.length > 0) {
        const videoLoaders = Array.from(videos).map(
          (video) =>
            new Promise((resolve) => {
              try {
                video.addEventListener("loadeddata", () => {
                  resolve();
                });
              } catch (error) {
                reject(error);
              }
            })
        );

        Promise.all(videoLoaders)
          .then(() => {
            gallery.resize();
          })
          .catch((error) => {
            console.error("Error loading videos: ", error);
          });
      }
    });
  });
}
