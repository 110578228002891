import opentype from "opentype.js";
import { isDarkModeActive } from "./darkmode";

const canvasSize = 160;
const fontSize = 90;
const darkModeActive = isDarkModeActive();

export async function generateCharset() {
  const charsetContainer = document.getElementById("charset");
  if (charsetContainer) {
    const woffUrl = charsetContainer.dataset.woffUrl;
    const font = await opentype.load(woffUrl);
    appendCharset(font, charsetContainer);
  }
}

function appendCharset(font, charsetContainer) {
  const glyphs = font.glyphs.glyphs;
  const breakingGlyphNames = [
    ".notdef",
    "space",
    ".null",
    "space.alt1",
    "space.alt2",
    "space.numerator",
    "space.denominator",
    "space.superior",
    "space.inferior",
    "uni0000",
    "CR",
  ];
  for (const idx in glyphs) {
    if (breakingGlyphNames.includes(glyphs[idx].name)) {
      continue;
    }
    const canvas = createCanvasWithGlyph({
      glyph: glyphs[idx],
      canvasSize: canvasSize,
      fontSize: fontSize,
      glyphMaxWidth: font.tables.hhea.advanceWidthMax,
      darkMode: darkModeActive,
    });
    charsetContainer.append(canvas);
  }
}

function createCanvasWithGlyph(options) {
  const canvas = document.createElement("canvas");
  canvas.width = options.canvasSize;
  canvas.height = options.canvasSize;
  canvas.className = "inline w-2 h-2 md:w-4 md:h-4";
  const ctx = canvas.getContext("2d");
  const baseline = options.canvasSize * 0.75;
  const leftOffset = options.canvasSize * 0.1;
  const path = options.glyph.getPath(leftOffset, baseline, options.fontSize);
  if (options.darkMode) {
    path.fill = "white";
  }
  path.draw(ctx);
  return canvas;
}
