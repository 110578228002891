import { createApp } from "petite-vue";

export function initOrderSummary() {
  createApp({
    $delimiters: ["${", "}"],
    acceptTos: false,
    acceptEula: false,
    acceptReturns: false,
  }).mount("#js-order-summary");
}
