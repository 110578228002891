import { createApp } from "petite-vue";

function initFlashMessage(message) {
  createApp({
    $delimiters: ["${", "}"],
    show: false,
    async construct() {
      this.show = true;
    },
  }).mount(message);
}

export function initFlashMessages() {
  const messages = document.querySelectorAll(".js-flash-message");
  messages.forEach((message) => {
    initFlashMessage(message);
  });
}
