export function initStickyBuyButton() {
  const activeArea = document.querySelector("#js-activate-sticky-buy-button");
  const button = document.querySelector("#js-sticky-buy-button");
  if (activeArea && button) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          button.classList.add("is-visible");
        } else {
          button.classList.remove("is-visible");
        }
      });
    });

    observer.observe(activeArea);
  }
}
