import { createApp } from "petite-vue";

export function initAddressFields() {
  const addressFields = document.querySelector("#js-address-fields");
  if (addressFields) {
    const initialCountryCode = addressFields.querySelector(
      'select[name="billingAddress[countryCode]"]'
    ).value;
    const states = addressFields.dataset.states;
    const initialState = addressFields.dataset.state;

    createApp({
      $delimiters: ["${", "}"],
      states: JSON.parse(states),
      activeCountryCode: initialCountryCode,
      state: initialState,
      get activeStates() {
        return this.states[this.activeCountryCode];
      },
      get activeStatesExist() {
        return Object.keys(this.activeStates).length > 0;
      },
    }).mount(addressFields);
  }
}
