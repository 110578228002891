import { createApp, reactive, nextTick } from "petite-vue";
import { formatPrice } from "../utilities/utilities";
import { trackEvent } from "./tracking-events";

function setupReactiveStore(presetLicences) {
  return reactive({
    licences: presetLicences,
    lineItems: "",
    cartTotal: 0,
    cartTotalNet: 0,
    loadingCounter: 0,
    get activeLicences() {
      const activeLicences = [];
      for (let licence in this.licences) {
        if (this.licences[licence].amount > 0) {
          activeLicences.push(licence);
        }
      }
      return activeLicences;
    },
    async fetchCart() {
      const cartSearch = await fetch("/actions/commerce/cart/get-cart", {
        headers: {
          Accept: "application/json",
        },
      });
      const res = await cartSearch.json();
      this.refreshCart(res.cart);
    },
    updateNavCartCount(cartItemsCount) {
      if (
        !document.querySelector("#js-nav-cart-count") ||
        !document.querySelector("#js-nav-cart")
      ) {
        return;
      }
      // ideally the nav cart indicator should be a vue component, but we’ll save time here
      document.querySelector("#js-nav-cart-count").innerHTML = cartItemsCount;
      document.querySelector("#js-nav-cart").style.display =
        cartItemsCount > 0 ? "inline" : "none";
    },
    refreshCart(cart) {
      if (this.loadingCounter > 1) {
        return;
      }
      this.lineItems = cart.lineItems || [];
      this.cartTotal = cart.totalPrice;
      this.cartTotalNet = cart.totalPrice - cart.totalTax;
      this.updateNavCartCount(cart.lineItems.length);
      document.dispatchEvent(
        new CustomEvent("cartRefreshed", {
          bubbles: true,
          detail: { cartItemsCount: cart.lineItems.length },
        })
      );
    },
    formatPrice(price) {
      return formatPrice(document.querySelector("html").lang, price);
    },
  });
}

function createProduct(product, store) {
  createApp({
    $delimiters: ["${", "}"],
    productId: product.dataset.productId,
    variants: JSON.parse(product.dataset.variants),
    parentIds: JSON.parse(product.dataset.parentIds),
    isInCart: false,
    parentInCart: false,
    displayOtherLicencesBadge: false,
    loading: false,
    store,
    calculatePrice(key) {
      let prices = {};
      for (const licence in store.licences) {
        if (this.variants[licence]) {
          prices[licence] =
            this.variants[licence][key] * store.licences[licence].amount;
        }
      }
      const totalPrice = Object.keys(prices).reduce(
        (sum, licence) => sum + prices[licence],
        0
      );
      const desktopWebDiscount = Math.min(prices.web, prices.desktop) / 2;
      return totalPrice - desktopWebDiscount;
    },
    get fullPrice() {
      return this.calculatePrice("fullPrice");
    },
    get grossFullPrice() {
      return this.fullPrice * 1.19;
    },
    get price() {
      return this.calculatePrice("price");
    },
    get grossPrice() {
      return this.price * 1.19;
    },
    get licenceBadgeTitle() {
      const badgeLicences = [];
      for (const variant in this.variants) {
        if (this.variants[variant].lineItemId) {
          badgeLicences.push(this.variants[variant].title);
        }
      }
      return badgeLicences.join(", ");
    },
    init() {
      document.addEventListener("licenceChanged", (e) =>
        this.checkIfProductIsInCart(e)
      );
      document.addEventListener("cartRefreshed", (e) =>
        this.checkIfProductIsInCart(e)
      );
    },

    checkIfOtherLicencesBadgeShouldBeDisplayed() {
      // check if this product has any variants in the cart
      this.displayOtherLicencesBadge = Object.keys(this.variants).reduce(
        (displayBadge, variant) =>
          displayBadge || (this.variants[variant].lineItemId ?? 0) > 0,
        false
      );
    },

    async checkIfProductIsInCart(e) {
      if (this.loading) {
        return;
      }

      // reset all lineItemIds because products might’ve been removed on the backend
      // lineItemIds will be re-assigned in the next step
      for (const licenceKey in this.variants) {
        this.variants[licenceKey].lineItemId = null;
      }

      // prefill lineItemIds to check for licence badges later
      const allLicences = store.licences;
      for (const licenceKey in allLicences) {
        this.checkIfVariantIsInCart(licenceKey);
      }

      const activeLicences = store.activeLicences;
      if (activeLicences.length == 0) {
        // no active licences exist
        this.displayOtherLicencesBadge = false;
        this.isInCart = false;
        return;
      }

      let isInCart = true;
      for (let i = 0; i < activeLicences.length; i++) {
        isInCart = isInCart && this.checkIfVariantIsInCart(activeLicences[i]);
      }
      this.isInCart = isInCart;

      await nextTick();

      let parentInCart = false;
      for (let i = 0; i < this.parentIds.length; i++) {
        parentInCart =
          parentInCart || this.checkIfParentIsInCart(this.parentIds[i]);
      }
      this.parentInCart = parentInCart;

      if (isInCart || parentInCart) {
        this.displayOtherLicencesBadge = false;
      } else {
        // product is not in cart and no parent is in cart with the active licence configuration
        this.checkIfOtherLicencesBadgeShouldBeDisplayed();
      }
    },

    checkIfVariantIsInCart(licence) {
      // run through all lineItems of current cart
      for (let i = 0; i < store.lineItems.length; i++) {
        // look for an sku match
        if (this.variants[licence].sku == store.lineItems[i].sku) {
          // the licence variant is in cart, we can set the lineItemId to access elsewhere
          this.variants[licence].lineItemId = store.lineItems[i].id;
          // now check whether the amounts match (in cart and active licence)
          const lineItemAmount = parseFloat(
            store.lineItems[i].options["amount"]
          );
          const activeLicenceAmount = parseFloat(
            store.licences[licence].amount
          );
          return lineItemAmount == activeLicenceAmount;
        }
      }
      return false;
    },

    checkIfParentIsInCart(parentProductId) {
      // this is hacky, but checking the actual products would mean comparing all licence amounts again, and we’ve already done that, so I’m relying on the DOM here
      return (
        document.querySelectorAll(
          `#js-product-${parentProductId}.js-is-in-cart`
        ).length > 0
      );
    },

    addToCart: async function () {
      if (this.loading || this.parentInCart) {
        return;
      }
      this.isInCart = true; // let’s be optimistic
      store.loadingCounter++;
      let payload = { lineItems: {}, purchasables: {} };
      const activeLicences = store.activeLicences;
      for (let i = 0; i < activeLicences.length; i++) {
        const lineItemId = this.variants[activeLicences[i]].lineItemId;
        if (lineItemId) {
          payload.lineItems[lineItemId] = {
            note: store.licences[activeLicences[i]].amount,
            qty: 1,
            options: { amount: store.licences[activeLicences[i]].amount },
          };
        } else {
          payload.purchasables[i] = {
            id: this.variants[activeLicences[i]].id,
            note: store.licences[activeLicences[i]].amount,
            options: { amount: store.licences[activeLicences[i]].amount },
            qty: 1,
          };
        }
      }
      this.loading = true;
      const cartAdd = await fetch("/actions/commerce/cart/update-cart", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": window.csrfTokenValue,
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      const res = await cartAdd.json();
      this.loading = false;
      store.loadingCounter--;
      store.refreshCart(res.cart);
      trackEvent("BUY_PAGE_ADD_TO_CART");
    },

    removeFromCart: async function () {
      if (this.loading || this.parentInCart) {
        return;
      }
      this.isInCart = false; // let’s be optimistic
      store.loadingCounter++;
      let payload = {
        lineItems: {},
      };
      const activeLicences = store.activeLicences;
      for (let i = 0; i < activeLicences.length; i++) {
        const lineItemId = this.variants[activeLicences[i]].lineItemId;
        payload.lineItems[lineItemId] = { remove: true };
      }
      this.loading = true;
      const cartRemove = await fetch("/actions/commerce/cart/update-cart", {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": window.csrfTokenValue,
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      const res = await cartRemove.json();
      for (let i = 0; i < activeLicences.length; i++) {
        this.variants[activeLicences[i]].lineItemId = null;
      }
      this.loading = false;
      store.loadingCounter--;
      store.refreshCart(res.cart);
      trackEvent("BUY_PAGE_REMOVE_FROM_CART");
    },
  }).mount("#" + product.id);
}

function createStickyCart(stickyCart, store) {
  createApp({
    $delimiters: ["${", "}"],
    store,
  }).mount(stickyCart);
}

function createLicenceSelector(licenceSelector, store) {
  createApp({
    $delimiters: ["${", "}"],
    store,
  }).mount(licenceSelector);
}

export function initBuyPage() {
  if (document.querySelector("#js-buy-page")) {
    const licenceSelector = document.querySelector("#js-licence-selector");
    const presetLicences = JSON.parse(
      licenceSelector.dataset.presetLicenceAmounts
    );
    const store = setupReactiveStore(presetLicences);
    createLicenceSelector(licenceSelector, store);
    const stickyCart = document.querySelector("#js-sticky-cart");
    createStickyCart(stickyCart, store);

    const products = document.querySelectorAll(".js-product");
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      createProduct(product, store);
    }
  }
}
